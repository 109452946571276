import { NextPage, GetServerSideProps, InferGetServerSidePropsType } from 'next'
import {
  Flex,
  Box,
} from 'components/primitives'
import Layout from 'components/Layout'
import { paths } from '@reservoir0x/reservoir-sdk'
import { ComponentPropsWithoutRef, useContext, useEffect, useState } from 'react'
import { useMarketplaceChain, useMounted } from 'hooks'
import supportedChains, { DefaultChain } from 'utils/chains'
import { Head } from 'components/Head'
import { ChainContext } from 'context/ChainContextProvider'
import { ChainStats } from 'components/home/ChainStats'
import useTopSellingCollections from 'hooks/useTopSellingCollections'
import fetcher from 'utils/fetcher'
import Hero_6 from 'components/hero/hero_6'
import Process from 'components/blog/process'
import { useTheme } from 'next-themes'
import { NORMALIZE_ROYALTIES } from '../_app'
import { useMediaQuery } from 'react-responsive'
import { CollectionRankingsTable } from 'components/rankings/CollectionRankingsTable'
import { useCollections } from '@reservoir0x/reservoir-kit-ui'
import { CollectionsSortingOption } from 'components/common/CollectionsTimeDropdown'

type Props = InferGetServerSidePropsType<typeof getServerSideProps>

const IndexPage: NextPage<Props> = ({ ssr }) => {
  const isSSR = typeof window === 'undefined'
  const marketplaceChain = useMarketplaceChain()
  const isMounted = useMounted()
  const isSmallDevice = useMediaQuery({ maxWidth: 905 }) && isMounted

  // not sure if there is a better way to fix this
  const { theme: nextTheme } = useTheme()
  const [theme, setTheme] = useState<string | null>(null)
  const { chain } = useContext(ChainContext)
  const [sortByTime, setSortByTime] =
    useState<CollectionsSortingOption>('1DayVolume')
  useEffect(() => {
    if (nextTheme) {
      setTheme(nextTheme)
    }
  }, [nextTheme])


  const { data: topSellingCollectionsData } = useTopSellingCollections(
    {
      period: '24h',
      includeRecentSales: true,
      limit: 20,
      fillType: 'sale',
    },
    {
      revalidateOnMount: true,
      refreshInterval: 300000,
      fallbackData: ssr.topSellingCollections[marketplaceChain.id]?.collections
        ? ssr.topSellingCollections[marketplaceChain.id]
        : null,
    },
    isMounted ? chain?.id : undefined
  )
  let collectionQuery: Parameters<typeof useCollections>['0'] = {
    limit: 20,
    sortBy: sortByTime,
    includeMintStages: true,
  }
  const { data, fetchNextPage, isFetchingPage, isValidating } = useCollections(
    collectionQuery,
    {
      fallbackData: [ssr.collection],
    }
  )
  let collections = data || []
  let volumeKey: ComponentPropsWithoutRef<
    typeof CollectionRankingsTable
  >['volumeKey'] = 'allTime'

  switch (sortByTime) {
    case '1DayVolume':
      volumeKey = '1day'
      break
    case '7DayVolume':
      volumeKey = '7day'
      break
    case '30DayVolume':
      volumeKey = '30day'
      break
  }
  //const topCollection = topSellingCollectionsData?.collections?.[0]

  return (
    <Layout>
      <Hero_6/>
      <Head />
      <Box
      className='bg-jacarta-50 dark:bg-jacarta-700'
        css={{
          p: 24,
          height: '100%',
          '@bp800': {
            p: '$4',
          },
        }}
      >
        {/* TODO: Create Banners for ads here */}
        {!isSmallDevice ? <ChainStats /> : null}
        <Flex className='mb-4 sm:my-4 gap-8'
          direction="column"
        >
          
          {isSSR || !isMounted ? null : (
            <CollectionRankingsTable
            collections={collections}
            volumeKey={volumeKey}
            loading={isValidating}
            />
          )}
        </Flex>
      </Box>
      <Process/>
    </Layout>
  )
}

type TopSellingCollectionsSchema =
  paths['/collections/top-selling/v2']['get']['responses']['200']['schema'];

type ChainTopSellingCollections = Record<string, TopSellingCollectionsSchema>;

type CollectionSchema =
  paths['/collections/v7']['get']['responses']['200']['schema'];

export const getServerSideProps: GetServerSideProps<{
  ssr: {
    topSellingCollections: ChainTopSellingCollections,
    collection: CollectionSchema
  }
}> = async ({ params, res }) => {
  const chainPrefix = params?.chain || ''
  const chain =
    supportedChains.find((chain) => chain.routePrefix === chainPrefix) ||
    DefaultChain;

  const topSellingCollections: ChainTopSellingCollections = {};
  let collectionData = {}; // Placeholder for the data for Page 2

  try {
    // Fetching data for Page 1
    const response = await fetcher(
      `${chain.reservoirBaseUrl}/collections/top-selling/v2?period=24h&includeRecentSales=true&limit=9&fillType=sale`,
      {
        headers: {
          'x-api-key': process.env.RESERVOIR_API_KEY || '',
        },
      }
    );

    topSellingCollections[chain.id] = response.data;

    // Additional code to fetch data for Page 2
    const collectionQuery = {
      sortBy: '1DayVolume',
      normalizeRoyalties: NORMALIZE_ROYALTIES,
      limit: 20,
      // Add any additional parameters needed for the request
    };

    const collectionResponse = await fetcher(
      `${chain.reservoirBaseUrl}/collections/v7`,
      collectionQuery,
      {
        headers: {
          'x-api-key': process.env.RESERVOIR_API_KEY || '',
        },
      }
    );

    collectionData = collectionResponse.data;

  } catch (e) {
    // Handle any errors
  }

  // Set cache headers for the response
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=120, stale-while-revalidate=180'
  );

  // Return both sets of data
  return {
    props: {
      ssr: {
        topSellingCollections,
        collection: collectionData // Add this line
      },
    },
  };
};

export default IndexPage
