import Link from 'next/link'
import React, {
  useState,
  useContext,
  useEffect,
  FC,
} from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
// import required modules
import { Autoplay, EffectFade, Swiper as SwiperType  } from 'swiper'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-fade'
import { db, app } from '../../firebase/clientApp'
import { Timestamp, collection, getDocs } from 'firebase/firestore'
import { ChainContext } from 'context/ChainContextProvider'
import { useCollections } from '@reservoir0x/reservoir-kit-ui'
import useTopSellingCollections, { TopSellingCollectionv2Data } from 'hooks/useTopSellingCollections'
import { paths } from '@reservoir0x/reservoir-sdk'


type AdType = {
  address: string
  enddate: Timestamp
  image: string
  name: string
  startdate: Timestamp
}

const Hero_6: FC = () => {
  const { data, error, isValidating } = useTopSellingCollections();
  const collections = data?.collections;
  const collectionsArray = collections;
  const adBanners = [1, 5, 0, 4, 3, 3]
  const multiView = [0, 1, 2, 3]
  const singleView = [0]
  const [ads, setAds] = useState<AdType[]>([])
  const [adsFetched, setAdsFetched] = useState(false)
  const [sliced1, setSliced1] = useState<
  paths['/collections/top-selling/v2']['get']['responses']['200']['schema']['collections']
  >([])
  const [sliced2, setSliced2] = useState<
  paths['/collections/top-selling/v2']['get']['responses']['200']['schema']['collections']
  >([])
  const [sliced3, setSliced3] = useState<
  paths['/collections/top-selling/v2']['get']['responses']['200']['schema']['collections']
  >([])
  const { chain } = useContext(ChainContext)

  const swiperRef = React.useRef<SwiperType | null>(null);
  const swiperRef2 = React.useRef<SwiperType | null>(null);
  const swiperRef3 = React.useRef<SwiperType | null>(null);

  useEffect(() => {
    swiperRef.current?.update();
  }, [sliced1]);
  useEffect(() => {
    swiperRef2.current?.update();
  }, [sliced2]);
  useEffect(() => {
    swiperRef3.current?.update();
  }, [sliced3]);
  

  useEffect(() => {
    if(!collectionsArray){
      return;
    }
    let s1 = [...collectionsArray.slice(0, 4), ...collectionsArray.slice(10, 14)];
    let s2 = [...collectionsArray.slice(4, 5), ...collectionsArray.slice(14, 15)];
    let s3 = [...collectionsArray.slice(6, 10), ...collectionsArray.slice(16, 20)];
  
    let tempS1 = [...s1];
    let tempS2 = [...s2];
    let tempS3 = [...s3];
  
    let adCounter = 0;
  
    getDocs(collection(db, 'ads'))
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const newDoc = doc.data() as AdType;
          const currentTimestampInSeconds: number = Date.now() / 1000;
          if (newDoc.enddate.seconds >= currentTimestampInSeconds) {
            setAds((prevAds) => [...prevAds, newDoc]);
  
            if (adCounter <= 1) {
              const currentItem = tempS1[adBanners[adCounter]];
              tempS1[adBanners[adCounter]] = {
                  ...currentItem,
                  image: newDoc.image,
                  name: newDoc.name,
                  id: newDoc.address,
                  floorAsk: currentItem ? { ...currentItem.floorAsk, price: undefined } : { price: undefined },
              };
          } else if (adCounter >= 2 && adCounter <= 3) {
              const currentItem = tempS3[adBanners[adCounter]];
              tempS3[adBanners[adCounter]] = {
                  ...currentItem,
                  image: newDoc.image,
                  name: newDoc.name,
                  id: newDoc.address,
                  floorAsk: currentItem ? { ...currentItem.floorAsk, price: undefined } : { price: undefined },
              };
          }
            adCounter++;
          }
        });
  
        // After processing all ads, check if the ad slots have been filled
        for (let i = 0; i < adBanners.length; i++) {
          if (!tempS1[adBanners[i]] || !tempS1[adBanners[i]].id) {
            tempS1[adBanners[i]] = s1[adBanners[i]];
        }
        
          if (!tempS3[adBanners[i]] || !tempS3[adBanners[i]].id) {
            tempS3[adBanners[i]] = s3[adBanners[i]];
        }
        }
  
        setSliced1(tempS1);
        setSliced2(tempS2);
        setSliced3(tempS3);
      })
      .catch((error) => {
        console.error('Error getting documents: ', error);
      });
    setAdsFetched(true);
  }, [collections]);
  
  if (isValidating) {
    return <></>
  }
  //console.log(fallback.collections.collections[1].floorAsk.price)
  return (
    <>
      {/* <!-- Hero --> */}
      <section className="relative px-6 pb-8 py-4">
        <h1 className="font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl text-center mb-10">
          Discover, Collect & Sell
          <span className="animate-gradient"> Creative NFTs</span>
        </h1>
        {ads.length >= 0 && (
          <div className="flex flex-col gap-5 lg:flex-row">
            <div className="w-full lg:w-1/3">
              <div className="grid grid-cols-2 grid-rows-2 gap-5">
                {multiView.map((item, itemID) => {
                  return (
                    <div key={sliced1![itemID]?.id}>
                      <Swiper
                      key={`${sliced1![itemID]?.id}S`}
                        onSwiper={(swiper) => {swiperRef.current = swiper}}
                        spaceBetween={0}
                        effect={'fade'}
                        navigation={true}
                        modules={[Autoplay, EffectFade]}
                        loop={true}
                        autoplay={{
                          delay: 3000,
                          disableOnInteraction: false,
                        }}
                        className="mySwiper max-w-full"
                      >
                        <SwiperSlide key={`slide1-${sliced1![itemID]?.id??"A"}`}>
                          <div className="relative overflow-hidden rounded-2.5xl bg-white dark:bg-jacarta-700 max-w-[300px]">
                            <figure className="relative">
                              <Link
                                href={`/collection/${chain.name}/${sliced1![itemID]?.id}`}
                              >
                                <div className="group block after:absolute after:inset-0 after:block after:bg-jacarta-900/20">
                                  <img
                                    src={sliced1![itemID]?.image}
                                    alt={sliced1![itemID]?.name}
                                    className="w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105 xs:max-h-[200px] ms:max-h-[300px] xs:min-h-[200px] md:min-h-[300px]"
                                  />
                                </div>
                              </Link>
                            </figure>
                            <div className="pointer-events-none absolute bottom-0 w-full p-5 bg-[#00000040]">
                              <h2 className="font-display text-base leading-none text-white xl:text-lg">
                                {sliced1![itemID]?.name}
                              </h2>
                              <span className="text-2xs text-white">{`Floor: ${
                                sliced1![itemID!]?.floorAsk?.price
                                  ? sliced1![itemID!]?.floorAsk?.price?.amount
                                      ?.native
                                  : '-'
                              } ${sliced3![itemID + 4]?.floorAsk?.price?.currency?.symbol}`}</span>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide key={`slide2-${sliced1![itemID + 4]?.id??"B"}`}>
                          <div className="relative overflow-hidden rounded-2.5xl bg-white dark:bg-jacarta-700 max-w-[300px]">
                            <figure className="relative">
                              <Link
                                href={`/collection/${chain.name}/${
                                  sliced1![itemID + 4]?.id
                                }`}
                              >
                                <div className="group block after:absolute after:inset-0 after:block after:bg-jacarta-900/20">
                                  <img
                                    src={sliced1![itemID + 4]?.image}
                                    alt={sliced1![itemID + 4]?.name}
                                    className="w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105 xs:max-h-[200px] ms:max-h-[300px] xs:min-h-[200px] md:min-h-[300px]"
                                  />
                                </div>
                              </Link>
                            </figure>
                            <div className="pointer-events-none absolute bottom-0 w-full p-5 bg-[#00000040]">
                              <h2 className="font-display text-base leading-none text-white xl:text-lg">
                                {sliced1![itemID + 4]?.name}
                              </h2>
                              <span className="text-2xs text-white">{`Floor: ${
                                sliced1![itemID + 4]?.floorAsk?.price
                                  ? sliced1![itemID + 4]?.floorAsk?.price?.amount
                                      ?.native
                                  : '-'
                              } ${sliced3![itemID + 4]?.floorAsk?.price?.currency?.symbol}`}</span>
                            </div>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="w-full lg:w-1/3">
              {singleView.map((item, itemID) => {
                return (
                  <div key={sliced2![itemID]?.id}>
                    <Swiper
                    key={`${sliced2![itemID]?.id}S`}
                    onSwiper={(swiper) => {swiperRef2.current = swiper}}
                      spaceBetween={0}
                      effect={'fade'}
                      navigation={true}
                      modules={[Autoplay, EffectFade]}
                      loop={true}
                      autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                      }}
                      className="mySwiper max-w-[100%]"
                    >
                      <SwiperSlide key={`slide2-${sliced2![itemID]?.id??"A"}`}>
                        <div className="relative overflow-hidden rounded-2.5xl bg-white dark:bg-jacarta-700">
                          <figure className="relative">
                            <Link
                              href={`/collection/${chain.name}/${sliced2![itemID]?.id}`}
                            >
                              <div className="group block after:absolute after:inset-0 after:block after:bg-jacarta-900/20">
                                <img
                                  src={sliced2![itemID]?.image}
                                  alt={sliced2![itemID]?.name}
                                  className="w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105 md:min-h-[620px]"
                                />
                              </div>
                            </Link>
                          </figure>
                          <div className="pointer-events-none absolute bottom-0 w-full p-5 bg-[#00000040]">
                            <h2 className="font-display text-base leading-none text-white xl:text-lg">
                              {sliced2![itemID]?.name}
                            </h2>
                            <span className="text-2xs text-white">{`Floor: ${
                              sliced2![itemID]?.floorAsk?.price
                                ? sliced2![itemID]?.floorAsk?.price?.amount
                                    ?.native
                                : '-'
                            } ${sliced3![itemID + 4]?.floorAsk?.price?.currency?.symbol}`}</span>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide key={`slide2-${sliced2![itemID+1]?.id??"B"}`}>
                        <div className="relative overflow-hidden rounded-2.5xl bg-white dark:bg-jacarta-700">
                          <figure className="relative">
                            <Link
                              href={`/collection/${chain.name}/${
                                sliced2![itemID + 1]?.id
                              }`}
                            >
                              <div className="group block after:absolute after:inset-0 after:block after:bg-jacarta-900/20">
                                <img
                                  src={sliced2![itemID + 1]?.image}
                                  alt={sliced2![itemID + 1]?.name}
                                  className="w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105 md:min-h-[620px]"
                                />
                              </div>
                            </Link>
                          </figure>
                          <div className="pointer-events-none absolute bottom-0 w-full p-5 bg-[#00000040]">
                            <h2 className="font-display text-base leading-none text-white xl:text-lg">
                              {sliced2![itemID + 1]?.name}
                            </h2>
                            <span className="text-2xs text-white">{`Floor: ${
                              sliced2![itemID + 1]?.floorAsk?.price
                                ? sliced2![itemID + 1]?.floorAsk?.price?.amount
                                    ?.native
                                : '-'
                            } ${sliced3![itemID + 4]?.floorAsk?.price?.currency?.symbol}`}</span>
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                )
              })}
            </div>
            <div className="w-full lg:w-1/3">
              <div className="grid grid-cols-2 grid-rows-2 gap-5">
                {multiView.map((item, itemID) => {
                  return (
                    <div key={sliced3![itemID]?.id}>
                      <Swiper
                      key={`${sliced3![itemID]?.id}S`}
                      onSwiper={(swiper) => {swiperRef3.current = swiper}}
                        spaceBetween={0}
                        effect={'fade'}
                        navigation={true}
                        modules={[Autoplay, EffectFade]}
                        loop={true}
                        autoplay={{
                          delay: 3000,
                          disableOnInteraction: false,
                        }}
                        className="mySwiper max-w-[100%]"
                      >
                        <SwiperSlide key={`slide3-${sliced3![itemID]?.id??"A"}`}>
                          <div className="relative overflow-hidden rounded-2.5xl bg-white dark:bg-jacarta-700 max-w-[300px]">
                            <figure className="relative">
                              <Link
                                href={`/collection/${chain.name}/${sliced3![itemID]?.id}`}
                              >
                                <div className="group block after:absolute after:inset-0 after:block after:bg-jacarta-900/20">
                                  <img
                                    src={sliced3![itemID]?.image}
                                    alt={sliced3![itemID]?.name}
                                    className="w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105 xs:max-h-[200px] ms:max-h-[300px] xs:min-h-[200px] md:min-h-[300px]"
                                  />
                                </div>
                              </Link>
                            </figure>
                            <div className="pointer-events-none absolute bottom-0 w-full p-5 bg-[#00000040]">
                              <h2 className="font-display text-base leading-none text-white xl:text-lg">
                                {sliced3![itemID]?.name}
                              </h2>
                              <span className="text-2xs text-white">{`Floor: ${
                                sliced3![itemID]?.floorAsk?.price
                                  ? sliced3![itemID]?.floorAsk?.price?.amount
                                      ?.native
                                  : '-'
                              } ${sliced3![itemID + 4]?.floorAsk?.price?.currency?.symbol}`}</span>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide key={`slide3-${sliced3![itemID+4]?.id??"B"}`}>
                          <div className="relative overflow-hidden rounded-2.5xl bg-white dark:bg-jacarta-700 max-w-[300px]">
                            <figure className="relative">
                              <Link
                                href={`/collection/${chain.name}/${sliced3![itemID+4]?.id}`}
                              >
                                <div className="group block after:absolute after:inset-0 after:block after:bg-jacarta-900/20">
                                  <img
                                    src={sliced3![itemID + 4]?.image}
                                    alt={sliced3![itemID + 4]?.name}
                                    className="w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105 xs:max-h-[200px] ms:max-h-[300px] xs:min-h-[200px] md:min-h-[300px]"
                                  />
                                </div>
                              </Link>
                            </figure>
                            <div className="pointer-events-none absolute bottom-0 w-full p-5 bg-[#00000040]">
                              <h2 className="font-display text-base leading-none text-white xl:text-lg">
                                {sliced3![itemID + 4]?.name}
                              </h2>
                              <span className="text-2xs text-white">{`Floor: ${
                                sliced3![itemID + 4]?.floorAsk?.price
                                  ? sliced3![itemID + 4]?.floorAsk?.price?.amount
                                      ?.native
                                  : '-'
                              } ${sliced3![itemID + 4]?.floorAsk?.price?.currency?.symbol}`}</span>
                            </div>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </section>
      {/* <!-- end hero --> */}
    </>
  )
}

export default Hero_6
