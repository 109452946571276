import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBrGa-Ucrn2XHrsDAZPt6t_5x0Mc23xIug",
  authDomain: "dijt-e5b0e.firebaseapp.com",
  projectId: "dijt-e5b0e",
  storageBucket: "dijt-e5b0e.appspot.com",
  messagingSenderId: "1038518913829",
  appId: "1:1038518913829:web:e5273d3142d9032d94a8cc",
  measurementId: "G-JP7BYT2FL1"
};

export const app = initializeApp(firebaseConfig);
    
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);
export const storage = getStorage(app);

//export const analytics = getAnalytics(app);